// ***  Original coding by GeorgiNikolov
// ***  https://codepen.io/gbnikolov/pen/yLwXaj

import React, { useState, useRef, useEffect } from "react";

const LetterFall = (props) => {

    const canvasRef = useRef(null);
    const content = document.getElementsByClassName('content');

    const [columns,setColumns] = useState( window.innerWidth / props.textFontSize );

    const [dimensions,setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {

        window.addEventListener('resize', windowResized);

        const bestHeight = () => {
            return props.pageHeight < window.innerHeight ? window.innerHeight : props.pageHeight;
        }

        const canvas  = canvasRef.current;
        canvas.width  = dimensions.width;
        canvas.height = bestHeight();

        const ctx = canvas.getContext('2d');

        const message = props.matrixText;
        let  drops = [];

        function windowResized(){
                 setDimensions({
                     width: window.innerWidth,
                     height:bestHeight()
                 });
                 setColumns( window.innerWidth / props.textFontSize);
         }

        function countCols(){
            for (let i = 0; i < columns; i++) {
                drops.push(1);
            }
        }

        function draw() {

            let copy = message.split('');

            let l_color;
            if (Math.random() < props.textAlternateColorRatio) {
                l_color = props.textAlternateColorList[Math.floor(Math.random() * props.textAlternateColorList.length)];
            }
            else {
                l_color = props.textMainColor;
            }

            ctx.fillStyle = props.backgroundColor;
            ctx.fillRect(0, 50, dimensions.width, bestHeight());

            for (let i = 0; i <  props.pageHeight; i++) {
                let text = copy[Math.floor(Math.random() * message.length)];
                ctx.fillStyle = l_color;
                ctx.fillText(text, i * props.textFontSize, drops[i] * props.textFontSize+50);
                drops[i]++;

                if (drops[i] * props.textFontSize> (dimensions.height * 2 / 3) && Math.random() > .85) { /*reset*/
                    drops[i] = 0;
                    drops[i]++;
                }
            }
        }

        // Loop the animation
        countCols();
        const l_intervalId = setInterval(() => draw(message), props.delay);

        return () => {
            window.removeEventListener('resize', windowResized);
            clearInterval(l_intervalId);
        }
        },[dimensions,content, props.pageHeight, columns, window]);

    return  (
        <canvas ref = { canvasRef } className="" id = "letterFall"/>
    );
}

export default LetterFall;


