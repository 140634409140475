import React, {useRef} from "react";
import theProjects from "../assets/projects.json";
import {Link} from "react-router-dom";
import ViewPortSize from "../components/hooks/ViewportSize";

const ProjectGallery = (props) => {

    const projects = sortItems(theProjects.projects);
    const projectsRef = useRef(null);


    ViewPortSize( props, projectsRef, props.before );

    function sortItems( theItems ){
        const items = [];
        for (let i = 0; i <= theItems.length+1 ; i++) {
            for( let project of theProjects.projects ){
                if (i === project.displayOrder) {
                    items[i] = project;
                }
            }
        }
        return items;
    }

    return (
        <div className = "container" ref={ projectsRef }  >

                <div className="ui cards">
                    {
                        projects.map( (project, index) => {
                            return (
                                <div key={index} className="card" style={{ height:"auto", width:"30%" }}>

                                    <div className="image">
                                        <img
                                        src = { require('../assets/images/' + project.hero) }
                                        alt = "Hero"
                                        />
                                    </div>

                                    <div className="content">
                                        <div className="header">{ project.name }</div>
                                        <div className="meta">{ project.type }</div>
                                        <div className="description">
                                            { project.description }
                                        </div>
                                    </div>

                                    <div className="extra content">
                                        <Link to={{ pathname:"/project/view", search:"?id="+project.id }}>
                                            <button className="ui fluid inverted blue button tinny ">More</button>
                                        </Link>
                                    </div>
                                </div>
                                );
                            })
                        }
                  </div>
        </div>
    ) // return
}// component

export default ProjectGallery;

