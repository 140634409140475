import React from "react";
import {Link} from "react-router-dom";

const Navigation = ({logo}) =>{
    return(
        <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-dark fixed-top backBlur">
                <Link className="navbar-brand" to="/">
                        <img src={logo} width="auto" height="40" alt="logo"/>
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#mainNavbar" aria-controls="mainNavbar"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>

                <div className=" ml-4 collapse navbar-collapse" id="mainNavbar">

                    <ul className="navbar-nav  ">
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">About</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link brand-pink" to="/portfolio">Work</Link>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <Link className="nav-link  brand-pink " to="/contact">Contact</Link>*/}
                        {/*</li>*/}
                    </ul>

                </div>
            </nav>
        </div>
    );
}

export default Navigation;