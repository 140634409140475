import React from 'react';

const Footer = ({details} )=> {
    return (

        <div className="container-fluid m-4  ">

            <div className="container ">
                <div className="ui inverted animated small  list ">

                    <div className="item">
                        <i className="mail pink icon"/>
                        <div className="content">
                            <a href={"mailto:" + details.email }
                               target="_blank"
                               rel="noopener noreferrer" >
                                { details.email }
                            </a>
                        </div>

                    </div>

                    <div className="item ">
                        <i className="phone pink icon "/>
                        <div className="content">
                            <span className="white">{ details.tel }</span>
                        </div>

                    </div>

                    <a className="item"
                       href={ details.linkedIn_comp }
                       target="_blank"
                       rel="noopener noreferrer" >
                        <i className="linkedin icon pink"/>
                    </a>
                </div>
         </div>
            <div align="center">
                <div className="ui inverted relaxed small horizontal list ">

                    <div className="item">
                        <i className="copyright pink icon"/>
                        <span className="white">Codecrafting - 2022 </span>
                    </div>
                </div>
            </div>

            </div>
    );
}

export default Footer;
