import React, {useRef} from "react";
import allProjects from "../assets/projects.json";
import ViewPortSize from "../components/hooks/ViewportSize";

const ViewProject = ( props ) => {

    const projectRef = useRef(null);
    ViewPortSize( props, projectRef );

    const projects = allProjects.projects;
    let   images = [];

    let project=[];
    const queryParams = new URLSearchParams( window.location.search );
    const id = queryParams.get('id');

    projects.map( ( item ) => {
        if(item.id == id ) {
            project = item;
            images = project.images;
        }
    });


    function codeButton(){
        if (project.codeUrl){
            return( <a href={ project.codeUrl } target="_blank"  type="button" className=" ui inverted button tiny"  rel="noopener noreferrer">Code</a>);
        }
    }

    function liveButton(){
        if(project.liveUrl){
            return( <a href={ project.liveUrl } target="_blank" type="button" className="ui inverted  button tiny"  rel="noopener noreferrer">Live</a>);
        }
    }

    return(
        <div id="project" className="container box" ref={projectRef}>
            <h1 className="pink">{project.name}</h1>

            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="d-block w-100" src={require('../assets/images/' +  project.hero )} alt="slide"/>
                    </div>

                    {
                        images.map( (slide)=>{
                            return(
                                <div className="carousel-item">
                                    <img className="d-block w-100" src={require('../assets/images/' +  slide )} alt="First slide"/>
                                </div>
                            )

                        })
                    }

                </div>

                <a className="carousel-control-prev"  href="#"  role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>

                <a className="carousel-control-next" href="#" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>

            <div className="container mt-4">
                <p className="text-light"> { project.summary }. </p>
                <p className="text-light"><strong>crafted with : </strong> { project.technologies }.</p>
                <div className="btn-group" role="group" aria-label="Basic example">
                     {codeButton()}
                     {liveButton()}
                </div>
            </div>

        </div>
    );
}

export default ViewProject;