import React, {useRef} from "react";
import ViewPortSize from "../components/hooks/ViewportSize";

const Maintenance = ( props ) => {

    const maintenanceRef = useRef(null);
    ViewPortSize( props, maintenanceRef );

    return(
        <div className="">
            <div className="jumbotron mt-4 bg-trans" ref={ maintenanceRef }>
                <h1 className="display-2">Codecrafting.</h1>
                <p className="lead">We are currently updating our website. </p>
                <p className="lead">Please check again in a few days.</p>
                <hr/>
                <h3>Contact</h3>
                <ul className="text-light">
                   <li><strong>tel: </strong><span className="contact">07988 601 719</span></li>
                   <li><strong>e-mail: </strong><span className="contact">kon.xen@gmail.com</span></li>
               </ul>

            </div>
        </div>
    );
}

export default Maintenance;