import React, {useRef} from 'react';
import ContactBlip from "../components/layout/cards/ContactBlip";
import founderPhoto from '../assets/images/cX.jpg';
import ViewPortSize from "../components/hooks/ViewportSize";

const AboutCx = ( props ) => {

    const aboutcxRef = useRef(null);
    ViewPortSize( props, aboutcxRef );

    return (
        <div id="aboutcx" className="container box" ref={ aboutcxRef }>
            <div className="">
                <img className="ui a small rounded left floated  image" src={ founderPhoto } alt="founder"/>
                <h1>Konstantinos Xenos</h1>
                <p><small className="white">Fullstack web developer</small></p>
                <ContactBlip details={ props.details } />
            </div>
            <br/>
            <div className="white t-18">
                <p> An interior/3D designer who turned web developer when around mid 2018 realised that retail design is a dying industry in the UK  and quite franky not as much fun as it used to be.</p>
                <p>
                    So in search of purpose and excitement embarked on a journey of acquiring knowledge and self reinvention.
                    Finally, after a few challenging years the time had come to start making a living out of web development.
                    Founded Codecrafting.net and started working on <a href="https://jobseeker.work/" className="pink" target="_blank" rel="noopener noreferrer" ><em>jobseeker.work </em></a>. An application designed to help job seekers to keep track of their applications without being tracked themselves.
                    The application is under development and looking for investors !
                </p>
                <p>
                    Have also built the websites for
                    <a href="https://arora-design.com/" className="pink" target="_blank" rel="noopener noreferrer" ><em> Arora Design Consultants</em> </a> and
                    <a href="https://michaelallenpartners.com/" className="pink" target="_blank" rel="noopener noreferrer" ><em> MichaelAllen & Partners </em></a>. Two prominent London based design consultancies.
                </p>

            </div>

        </div>
    );
}

export default AboutCx;
