import React, {useRef} from 'react';
import {Link} from "react-router-dom";
import ViewPortSize from "../components/hooks/ViewportSize";

const About = (props) => {

    const aboutRef = useRef(null);
    ViewPortSize(props,aboutRef);

    return (
        <div className="container" ref={ aboutRef } >
            <div className="container mb-6">
                <div className="box mw-80">
                    <h1 className="pink">This is the page where  we should be highlighting the biggest selling points of our story and brand, making a strong first impression on prospect clients. </h1>
                </div>
            </div>

            <div className="container white fade-in mt-4">
               <h1 className="brand-colour">
                  <strong>However</strong>, we don't have any selling points nor brand story.
               </h1>
            </div>

            <div className="container box mt-4 fade-in-2" >
                <div>
                    <h1 className="ui header pink"> What we do have is...</h1>
                    <div className="t-20">
                        <p>
                            ..a chap called <Link className="pink" to="/about/cx">Constantinos</Link> with
                            nearly <strong> 20 years of hard earned experience</strong> in the fields of 3D design and brand environments, directly applied to web development.
                        </p>
                        <p className=""> As the principles and methodology between design and web development are surprisingly, the same!,
                            we can help you with design, build and deploy your website or web application through a pain free process.
                        </p>
                        <p>
                            So do give as a call or drop us an e-mail to discuss your project. Come on, don't be shy.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default About;
