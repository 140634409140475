import React from 'react';

const ContactBlip =( {details} )=>{
    return (

            <div className="ui inverted medium horizontal list ">

                <div className="item">
                    <i className="mail pink icon"/>
                    <div className="content">
                        <a href={"mailto:" + details.email }
                           target="_blank"
                           rel="noopener noreferrer" >
                            {details.email}
                        </a>
                    </div>
                </div>

                <div className="item">
                    <i className="phone pink icon"/>
                    <div className="content white">
                        {details.tel}
                    </div>
                </div>

                <a className="item"
                   href={ details.gitlab }
                   target="_blank"
                   rel="noopener noreferrer" >
                    <i className="gitlab icon pink"/>
                </a>

                <a className="item"
                   href={details.linkedIn}
                   target="_blank"
                   rel="noopener noreferrer" >
                    <i className="linkedin icon pink"/>
                </a>

            </div>

    );

}

export default ContactBlip;
