import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './codecrafting.css';
import './assets/fonts/eurostile/font.css';


ReactDOM.render(
        <App />,
    document.getElementById('root')
);
