import React, { useRef } from "react";
import {Link} from "react-router-dom";
import ViewPortSize from "../components/hooks/ViewportSize";

const Home = ( props ) => {

    const homeRef = useRef(null);
    ViewPortSize( props, homeRef );

    return(
            <div id="home" className="jumbo fade-in" ref={ homeRef } >
                <h1 className="display-4 brand-colour mb-4">Codecrafting</h1>
                <p className="t-24"> Welcome. </p>

                <p className="t-20">
                    @ <span className="brand-colour">Codecrafting</span> we craft web solutions to suit your needs. Anything from simple web pages to complex web apps.
                </p>
                <br/>

                <p className="lead text-dark">
                    <Link className="ui inverted blue button " to="/about">more...</Link>
                </p>
            </div>
    );
}

export default Home;