import React, { useEffect } from 'react';

const ViewPortSize = ( props, reference ) => {

    useEffect(
        ()=>{
            sendHeight();
            window.addEventListener('resize', sendHeight);
            return () => {
                window.removeEventListener('resize', sendHeight);
            }
        },[props, window]);

    function sendHeight(){
        props.passData(reference.current.offsetHeight+150);
    }
}

export default ViewPortSize;
