import React,{ useState, useEffect } from "react";
import { BrowserRouter,Routes, Route } from "react-router-dom";
import configuration from "../src/assets/config.json";
import LetterFall from "./components/letterFall";
import Navigation from "./components/layout/Navigation";
import Footer from "./components/layout/Footer";
import Home from "./views/Home";
import Maintenance from "./views/Maintenace";
import About from "./views/About";
import AboutCx from "./views/AboutCx";
// import Contact from "./views/Contact";
import ProjectGallery from "./views/ProjectGallery";
import ViewProject from "./views/ViewProject";


import logoSvg from './assets/images/ccLogo.svg';

const App = ( props ) =>{

    const config = configuration.siteSettings;
    const [pageHeight,setPageHeight] = useState(null);

    function updatePageHeight(data){
        setPageHeight(data);
    }

    useEffect(()=>{
        updatePageHeight();
    },[props.data, window.innerHeight]);


    const renderNavigation= ()=>{
        if(!config.maintenance){
            return <Navigation logo={ logoSvg }/> }
    }

    const renderHome = ()=>{
        if(!config.maintenance){
            return <Route path="/"  element={ <Home passData={updatePageHeight}/>} />
        }else{
            return <Route path="/"  element={  <Maintenance/>} />
        }
    }

    const renderPages = ()=>{
        if(!config.maintenance){
            return (
                <Routes>
                    <Route path="/about" element={<About passData={updatePageHeight} details={configuration.details} />} />
                    <Route path="/about/cx" element={<AboutCx passData={updatePageHeight} details={configuration.details} />} />
                    {/*<Route path="/contact" element={<Contact passData={updatePageHeight}  details={configuration.details} />} />*/}
                    <Route path="/portfolio" element={<ProjectGallery passData={updatePageHeight} />} />
                    <Route path="/project/view" element={<ViewProject passData={updatePageHeight}/>} />
                </Routes>
        );}
    }


    return (
        <BrowserRouter>
        <div className="container-fluid">

            <div className="row">
                { renderNavigation()}
            </div>

            <div className="row">
                <LetterFall

                    textMainColor={"#A9A9A9"}//grey*/}
                    matrixText = {"Welcome to Codecrafting"}
                    backgroundColor = {"rgba(40, 44, 52, 0.2)"}
                    // backgroundColor = {"rgba(80, 64, 52, 0.2)"}//for testing
                    textFontSize = {"24"}
                    textAlternateColorRatio = {0.5}
                    delay = {85}
                    textAlternateColorList = {["#00adef", "#F500ED", "#A9A9A9", "#C0C0C0"]}
                    pageHeight = { pageHeight }
                />

                <div className="pageContent" id="page">
                    <div className="container" >
                        <Routes >
                            { renderHome() }
                        </Routes>

                        {renderPages()}
                    </div>
                </div>
            </div>

            <Footer logo={ logoSvg } details={configuration.details} />
        </div>

        </BrowserRouter>
    );
}

export default App;